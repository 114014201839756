<template>
  <div class="modal-container">
    <div class="modal-shadow" @click="closeModal"></div>
    <div class="modal-contant">
      <div class="close-modal-icon" @click="closeModal">
        <i class="fa-solid fa-xmark"></i>
      </div>
      <div class="contant-details">
        <div class="contant-details-left">
          <img :src="mainImage" alt="" />
          <div class="contant-details-left-bottom">
            <img
              v-if="selectedProject.fistImage"
              :src="selectedProject.fistImage.asset.url"
              @click="setNewMainImage(selectedProject.fistImage.asset.url)"
              alt=""
            />
            <img
              v-if="selectedProject.secondImage"
              :src="selectedProject.secondImage.asset.url"
              @click="setNewMainImage(selectedProject.secondImage.asset.url)"
              alt=""
            />
            <img
              v-if="selectedProject.thirdImage"
              :src="selectedProject.thirdImage.asset.url"
              @click="setNewMainImage(selectedProject.thirdImage.asset.url)"
              alt=""
            />
            <img
              v-if="selectedProject.fourthImage"
              :src="selectedProject.fourthImage.asset.url"
              @click="setNewMainImage(selectedProject.fourthImage.asset.url)"
              alt=""
            />
            <img
              v-if="selectedProject.fithImage"
              :src="selectedProject.fithImage.asset.url"
              @click="setNewMainImage(selectedProject.fithImage.asset.url)"
              alt=""
            />
          </div>
        </div>
        <div class="contant-details-right">
          <h1>{{ selectedProject.name }}</h1>
          <p>
            {{ selectedProject.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainImage: "",
    };
  },
  props: {
    closeModal: {
      type: Function,
    },
    selectedProject: {
      type: Object,
      default: {},
    },
  },
  watch: {
    selectedProject(value) {
      this.mainImage = value.fistImage.asset.url;
    },
  },
  methods: {
    setNewMainImage(item) {
      this.mainImage = item;
    },
  },
};
</script>

<style scoped>
.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-shadow {
  background: rgb(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
}

.modal-contant {
  max-width: 80%;
  min-width: 60%;
  height: 80vh;
  background: white;
  position: fixed;
  top: calc(50% + 48px);
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 90;
  overflow-y: auto;
}

.close-modal-icon {
  display: flex;
  justify-content: flex-end;
}

.close-modal-icon i {
  border: 1px solid #dddddd;
  margin: 2px;
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 50%;
}

.contant-details {
  display: flex;
}

.contant-details-left {
  width: 60%;
  height: calc(100% - 100px);
  padding: 10px;
}

.contant-details-left img {
  width: 100%;
}

.contant-details-left-bottom {
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.contant-details-left-bottom img {
  width: 80px;
  margin: 10px;
}
.contant-details-left-bottom img:hover {
  outline: 3px solid var(--primary-color);
  cursor: pointer;
}
.contant-details-right {
  width: 40%;
  height: 100%;
  padding: 10px;
}

.contant-details-right h1 {
  color: #565656;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}
.contant-details-right p {
  color: #6e6e6e;
  font-size: 15px;
  font-weight: 400;
  margin: 0 12px 0 10px;
}

@media (max-width: 770px) {
  .contant-details {
    flex-direction: column;
  }
  .contant-details-left {
    width: calc(100% - 20px);
  }
  .contant-details-right {
    width: 90%;
  }
}
@media (max-width: 400px) {
  .modal-contant {
    max-width: 90%;
  }
}
</style>