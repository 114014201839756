<template>
  <div class="home">
    <slide-show></slide-show>
    <about-section></about-section>
    <process></process>
    <why-us></why-us>
    <!-- <div id="services"> -->
    <our-services></our-services>
    <!-- </div> -->
    <new-projects></new-projects>
    <testimonials></testimonials>
  </div>
</template>

<script>
import SlideShow from "@/components/SlideShow.vue";
import Process from "@/components/Process.vue";
import WhyUs from "@/components/WhyUs.vue";
import OurServices from "@/components/OurServices.vue";
import NewProjects from "@/components/NewProjects.vue";
import Testimonials from "@/components/Testimonials.vue";
import AboutSection from "@/components/AboutSection.vue";
export default {
  name: "HomePage",
  components: {
    SlideShow,
    Process,
    WhyUs,
    OurServices,
    NewProjects,
    Testimonials,
    AboutSection,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
