<template>
  <div class="why-us">
    <div class="whyus-left" data-aos="flip-left" data-aos-duration="2000">
      <h3 class="section-title">WHY US</h3>
      <h1 class="section-sub-title">
        We always select best properties for you.
      </h1>
      <p>
        We mainly focus on the quality of our work and deliver it on time
        because of our high experience by considering and prioritizing the
        clients' wishes.
      </p>
      <p>
        We truly shape the world around us by making improvements of cleaning,
        decorating and re-equipping with the new technology for increasing
        productivity.
      </p>
      <div class="why-us-btn">
        <base-button to="/about" text="More about us" onBorder="20px" />
      </div>
    </div>
    <div class="whyus-right" data-aos="flip-right" data-aos-duration="2000">
      <img src="../assets/NRCltd3.png" alt="" />
    </div>
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";
export default {
  components: { BaseButton },
  name: "WhyUs",
};
</script>

<style scoped>
.why-us {
  display: flex;
  padding: 50px 0;
  justify-content: center;
}

.whyus-left {
  width: 40%;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whyus-left .section-sub-title {
  text-align: left !important;
  margin-bottom: 20px;
}

.whyus-left p {
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 10px;
}

.whyus-right {
  width: 40%;
}

.whyus-right img {
  width: 100%;
  height: 100%;
}

.why-us-btn {
  margin-top: 20px;
}

@media (max-width: 700px) {
  .why-us {
    flex-direction: column-reverse;
  }
  .whyus-left,
  .whyus-right {
    width: 80%;
    margin: 10px auto;
  }
}
</style>