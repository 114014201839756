<template>
  <div class="swipper-cont-section">
    <swiper
      :slidesPerView="1"
      :spaceBetween="30"
      :loop="true"
      :pagination="{
        clickable: true,
      }"
      :autoplay="true"
      :navigation="true"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide>
        <div class="carousel-container">
          <img
            v-if="slideImages.length && slideImages[0].homeImageOne"
            :src="slideImages[0].homeImageOne.asset.url"
            alt=""
          />
          <img
            v-if="isFetchDone && !slideImages[0].homeImageOne"
            src="../assets/NRCltd1.jpg"
            alt=""
          />
        </div>
        <div class="carousel-contant">
          <h1>Find your new home</h1>
          <p>You are in the real place, You can have the home of your dream.</p>
        </div>
        <div class="img-backdrop"></div>
      </swiper-slide>
      <swiper-slide>
        <div class="carousel-container">
          <img
            v-if="slideImages.length && slideImages[0].homeImageTwo"
            :src="slideImages[0].homeImageTwo.asset.url"
            alt=""
          />
          <img
            v-if="isFetchDone && !slideImages[0].homeImageTwo"
            src="../assets/NRCltd2.jpg"
            alt=""
          />
        </div>
        <div class="carousel-contant">
          <h1>Get your property with us</h1>
          <p>We construct and design the best property of your desire</p>
        </div>
        <div class="img-backdrop"></div>
      </swiper-slide>
      <swiper-slide>
        <div class="carousel-container">
          <img
            v-if="slideImages.length && slideImages[0].homeImageThree"
            :src="slideImages[0].homeImageThree.asset.url"
            alt=""
          />
          <img
            v-if="isFetchDone && !slideImages[0].homeImageThree"
            src="../assets/NRCltd3.png"
            alt=""
          />
        </div>
        <div class="carousel-contant">
          <h1>We execute your dream</h1>
          <p>
            Through construction and designing we execute the best of your dream
          </p>
        </div>
        <div class="img-backdrop"></div>
      </swiper-slide>
      <swiper-slide>
        <div class="carousel-container">
          <img
            v-if="slideImages.length && slideImages[0].homeImageFour"
            :src="slideImages[0].homeImageFour.asset.url"
            alt=""
          />
          <img
            v-if="isFetchDone && !slideImages[0].homeImageFour"
            src="../assets/NRCltd4.jpg"
            alt=""
          />
        </div>
        <div class="img-backdrop"></div>
        <div class="carousel-contant">
          <h1>No doubt about your architectural design</h1>
          <p>
            Niyo Rwanda Cad provide the best architectural design of buildings
            with the best Interior design
          </p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import "./style.css";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";

import sanityClient from "../client.js";
export default {
  name: "SlideShow",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Pagination, Navigation, Autoplay],
      slideImages: [],
      isFetchDone: false,
    };
  },
  created() {
    const query =
      '*[_type == "homeShow"]{homeImageOne{asset->{_id,url}},homeImageTwo{asset->{_id,url}},homeImageThree{asset->{_id,url}},homeImageFour{asset->{_id,url}}}';

    sanityClient.fetch(query).then((res) => {
      this.slideImages = res;
      this.isFetchDone = true;
    });
  },
};
</script>

<style scoped>
.swipper-cont-section {
  padding-top: 80px;
}
.carousel-container {
  width: 100%;
  height: calc(100vh - 80px);
}

.carousel-container img {
  width: 100%;
  height: 100%;
}

.img-backdrop {
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  background: rgba(35, 59, 87, 0.45);
  z-index: 40;
}

.carousel-contant {
  position: absolute;
  left: 40px;
  width: 50%;
  z-index: 50 !important;
}

.carousel-contant h1,
.carousel-contant p {
  color: white;
  font-size: 60px;
  text-align: left;
  margin-left: 30px;
}

.carousel-contant h1 {
  -webkit-text-stroke: 1px black;
  font-size: 60px;
}
.carousel-contant p {
  font-size: 25px;
  font-weight: 400;
  width: 80%;
}
@media (max-width: 800px) {
  .carousel-contant {
    width: 70%;
  }
  .carousel-contant h1 {
    font-size: 40px;
  }
  .carousel-contant p {
    font-size: 20px;
  }
}
@media (max-width: 400px) {
  .carousel-contant {
    width: 80%;
    left: 0;
    margin-left: 20px;
  }
}
</style>