<template>
  <div class="about-us">
    <div class="about-cover-img">
      <img src="../assets/NRCltd17.jpg" alt="" />
      <h1>More About us</h1>
    </div>
    <div class="more-about-us-container">
      <h2 class="other-section-title">Our Mission, Vision & Value</h2>
      <div class="app-default-underline"></div>
      <div class="more-about-us">
        <div class="more-about-us-card">
          <div class="more-about-us-card-left">
            <i class="fa-solid fa-check"></i>
          </div>
          <div class="more-about-us-card-right">
            <h4>Mission</h4>
            <p>
              To continually execute the projects that meet customer needs with
              innovative construction property and becoming the top construction
              company.
            </p>
          </div>
        </div>
        <div class="more-about-us-card">
          <div class="more-about-us-card-left">
            <i class="fa-solid fa-eye-low-vision"></i>
          </div>
          <div class="more-about-us-card-right">
            <h4>Vision</h4>
            <p>
              To be the leading construction company to the market and become
              the clients' first choice whereby completing projects with quality
              on time.
            </p>
          </div>
        </div>
        <div class="more-about-us-card">
          <div class="more-about-us-card-left">
            <i class="fa-solid fa-heart"></i>
          </div>
          <div class="more-about-us-card-right">
            <h4>Value</h4>
            <p>
              Promoting innovative concepts that help the business succeed and
              respect other people's emotions, desires, contributions, and thier
              points of view.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="meet-coe">
      <h2 class="other-section-title">Meet Our Founder & CEO</h2>
      <div class="app-default-underline"></div>
      <div class="meet-ceo-container">
        <div
          class="meet-ceo-left"
          data-aos="flip-left"
          data-aos-duration="2000"
        >
          <img src="../assets/CEO.png" alt="Emmanuel Niyomukiza" />
        </div>
        <div
          class="meet-ceo-right"
          data-aos="flip-right"
          data-aos-duration="2000"
        >
          <h3>Founder & Chief Executive Officer at Niyo Rwanda Cad ltd</h3>
          <p class="ceo-msg">
            Niyo Rwanda Cad was born with the aim of changing the world by
            building the best properties that satisfy the clients' needs.
          </p>
          <p class="ceo-name">Emmanuel Niyomukiza</p>
          <div class="ceo-social-media">
            <a href="https://twitter.com/EmmyNiyomukiza" target="_blank">
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a href="https://www.instagram.com/emmy_niyomukiza" target="_blank">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a href="mailto:n.emmy009@gmail.com" target="_blank">
              <i class="fa-solid fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.about-us {
  padding-top: 80px;
}

.about-cover-img {
  width: 100%;
}

.about-cover-img img {
  width: 100%;
  height: 25vh;
}

.about-cover-img h1 {
  position: absolute;
  top: 17%;
  left: 40px;
  color: white;
  -webkit-text-stroke: 1px black;
  font-size: 60px;
}

.more-about-us-container {
  background: #f2f2f2;
}

.more-about-us {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  margin: 40px auto 0;
  padding-bottom: 40px;
}

.more-about-us-card {
  width: 320px;
  display: flex;
  margin-bottom: 10px;
}

.more-about-us-card i {
  background: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
}

.more-about-us-card-right h4 {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
  color: #535353;
}

.more-about-us-card-right p {
  color: #757474;
}

.meet-coe {
  padding-bottom: 40px;
}

.meet-ceo-container {
  width: 70%;
  margin: 30px auto 0;
  display: flex;
  padding: 1px;
  justify-content: space-between;
}

.other-section-title {
  padding-top: 30px;
}

.meet-ceo-left {
  width: 40%;
}

.meet-ceo-left img {
  width: 100%;
}

.meet-ceo-right {
  width: 50%;
  padding: 20px;
}

.meet-ceo-right h3 {
  font-weight: 300;
  text-align: center;
  font-size: 30px;
  color: #535353;
}

.ceo-msg {
  font-weight: 100;
  text-align: center;
  font-size: 20px;
  color: #535353;
  margin-top: 20px;
}

.ceo-name {
  font-weight: 400;
  text-align: center;
  font-size: 18px;
  color: #000000;
  margin-top: 20px;
}

.ceo-social-media {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.ceo-social-media i {
  border: 1px solid var(--primary-color);
  padding: 10px;
  margin: 2px;
  font-size: 20px;
  color: var(--primary-color);
  cursor: pointer;
}

@media (max-width: 900px) {
  .meet-ceo-container {
    width: 80%;
  }
  .meet-ceo-right h3 {
    font-size: 28px;
  }
  .ceo-msg {
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .meet-ceo-right h3 {
    font-size: 25px;
  }
  .ceo-msg {
    font-size: 18px;
  }
}
@media (max-width: 730px) {
  .meet-ceo-container {
    width: 90%;
  }
  .meet-ceo-right h3 {
    font-size: 22px;
  }
  .ceo-msg {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .about-cover-img h1 {
    font-size: 50px;
    top: 20%;
  }
  .meet-ceo-container {
    flex-direction: column;
  }
  .meet-ceo-left,
  .meet-ceo-right {
    width: 100%;
  }
  .meet-ceo-right {
    padding: 0;
  }
}
@media (max-width: 460px) {
  .about-cover-img h1 {
    font-size: 40px;
    top: 22%;
  }
}

@media (max-width: 400px) {
  .about-cover-img h1 {
    font-size: 30px;
    -webkit-text-stroke: 1px white;
    top: 22%;
  }
}
</style>
