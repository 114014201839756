<template>
  <div class="services" id="services">
    <div class="services-title" data-aos="zoom-in" data-aos-duration="2000">
      <h3 class="section-title">WHAT WE DO</h3>
      <h1 class="section-sub-title">We provide the followings</h1>
    </div>
    <div
      class="services-description"
      data-aos="zoom-in"
      data-aos-duration="2000"
    >
      <div class="services-desc-card">
        <div class="services-desc-card-left">
          <i class="fa-solid fa-house"></i>
        </div>
        <div class="services-desc-card-right">
          <h4>Architectural Service</h4>
          <p>
            We provide architectural designs of Buildings, Roads, Bridges and
            the best Interior design.
          </p>
        </div>
      </div>
      <div class="services-desc-card">
        <div class="services-desc-card-left">
          <i class="fa-solid fa-school"></i>
        </div>
        <div class="services-desc-card-right">
          <h4>Engineering construction</h4>
          <p>
            We construct Buildings, Water reservoirs, Sewer systems, dams and do
            maintainance.
          </p>
        </div>
      </div>
      <div class="services-desc-card">
        <div class="services-desc-card-left">
          <i class="fa-solid fa-building-columns"></i>
        </div>
        <div class="services-desc-card-right">
          <h4>Supervision</h4>
          <p>
            We supervise a work from the beginning up to the end accordinging to
            the design.
          </p>
        </div>
      </div>
      <div class="services-desc-card">
        <div class="services-desc-card-left">
          <i class="fa-solid fa-church"></i>
        </div>
        <div class="services-desc-card-right">
          <h4>Construction permit</h4>
          <p>We help our clients get construction permits.</p>
        </div>
      </div>
      <div class="services-desc-card">
        <div class="services-desc-card-left">
          <i class="fa-solid fa-house-laptop"></i>
        </div>
        <div class="services-desc-card-right">
          <h4>Consultancy</h4>
          <p>
            We provide expert advice on available solutions and technical
            changes.
          </p>
        </div>
      </div>
      <div class="services-desc-card">
        <div class="services-desc-card-left">
          <i class="fa-solid fa-industry"></i>
        </div>
        <div class="services-desc-card-right">
          <h4>Electrical and plumbing</h4>
          <p>We provide Electrical and plumbing installation works</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurServices",
};
</script>

<style scoped>
.services {
  width: 100%;
  height: 50%;
  padding: 40px 0;
  background: #f2f2f2;
}

.services-btn {
  display: flex;
  justify-content: center;
}

.services-description {
  width: 85%;
  margin: 20px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.services-desc-card {
  width: 320px;
  margin: 15px 5px;
  padding: 10px;
  display: flex;
  border-radius: 8px;
  /* transition: box-shadow 0.8s ease-in-out; */
}

/* .services-desc-card:hover {
  box-shadow: 3px 1px 20px #b7b7b7;
} */

.services-desc-card-left i {
  background: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
}

.services-desc-card-right h4 {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
  color: #535353;
}

.services-desc-card-right p {
  color: #757474;
}
</style>