<template>
  <div class="about-section">
    <h3 class="section-title">GET TO KNOW US</h3>
    <h1 class="section-sub-title">We focus on high quality services</h1>
    <div class="about-section-container">
      <div
        class="about-section-left"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <img src="../assets/NRCltd13.jpg" alt="" />
      </div>
      <div
        class="about-section-right"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <div class="about-section-right-header">
          <div>
            <span>
              <i class="fa-solid fa-hammer"></i>
            </span>
            <h3>BUILDING QUALITY STANDARD</h3>
          </div>
          <div>
            <span>
              <i class="fa-solid fa-ruler-combined"></i>
            </span>
            <h3>DESIGN AWESOME PROPERTIES</h3>
          </div>
        </div>
        <p>
          Niyo Rwanda Cad is a construction company that executes projects, meet
          customers' needs with innovative construction property to the market
          whereby becoming the clients' first choice.
        </p>
        <div class="more-container">
          <base-button to="/about" text="Read More" onBorder="20px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";

export default {
  components: { BaseButton },
  name: "AboutSection",
};
</script>

<style scoped>
.about-section {
  padding: 40px 20px;
  overflow: hidden;
}
.about-section-container {
  display: flex;
  width: 85%;
  margin: 40px auto 0;
  justify-content: space-between;
  align-items: center;
}
.about-section-left {
  width: 40%;
  margin-right: 10px;
}
.about-section-left img {
  width: 100%;
  height: 38vh;
  /* border-radius: 40% 0 40% 0; */
  object-fit: cover;
}
.about-section-right {
  width: 55%;
  margin-left: 10px;
}
.about-section-right-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.about-section-right-header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-section-right-header span i {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--primary-color);
  color: white;
}
.about-section-right-header h3 {
  font-size: 16px;
  font-weight: 400;
  margin: 0 10px;
}
.about-section-right p {
  color: #515151;
  margin: 10px;
}

.more-container {
  margin: 20px 0 0 15px;
}

@media (max-width: 800px) {
  .about-section-container {
    flex-direction: column;
  }
  .about-section-left {
    width: 90%;
    margin-right: 0;
  }

  .about-section-right {
    width: 100%;
    margin-left: 0;
    margin-top: 30px;
  }

  .about-section-right-header > div {
    margin: 10px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 610px) {
  .about-section {
    padding: 40px 0;
  }
  .about-section-right-header {
    flex-direction: column;
  }
  .about-section-right-header > div {
    margin: 0 0 10px 0;
  }
}
</style>