<template>
  <div class="testimonial">
    <h3 class="section-title">TESTIMONIALS</h3>
    <h1 class="section-sub-title">What our customers are Saying</h1>
    <div
      class="testimonial-user"
      data-aos="zoom-in-down"
      data-aos-duration="2000"
      v-for="(item, index) in testimonyData"
      :key="index"
    >
      <div class="testimonial-user-left" v-if="item.personImage">
        <img :src="item.personImage.asset.url" alt="" />
      </div>
      <div class="testimonial-user-left" v-else>
        <img src="../assets/userImg.png" alt="" />
      </div>
      <div class="testimonial-user-right">
        <p>
          {{ item.message }}
        </p>
        <h3>{{ item.name }}</h3>
        <h4>{{ item.post }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import sanityClient from "../client.js";
export default {
  data() {
    return {
      testimonyData: [],
    };
  },
  created() {
    const query =
      '*[_type == "testimony"]{message,name,post,personImage{asset->{_id,url}}}';

    sanityClient.fetch(query).then((res) => {
      this.testimonyData = res;
    });
  },
};
</script>

<style scoped>
.testimonial {
  padding: 40px 0;
  background: #f2f2f2;
}

.testimonial-user {
  width: 60%;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-shadow: 2px 2px 6px #c6c6c6;
  background: #f8f8f8;
  border-radius: 5px;
}

.testimonial-user-left {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #eaeaea;
  overflow: hidden;
}
.testimonial-user-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-user-right {
  width: 80%;
}

.testimonial-user-right p {
  color: #606060;
}

.testimonial-user-right h3 {
  margin: 10px 0;
  font-weight: 500;
  color: #606060;
}
.testimonial-user-right h4 {
  margin: 10px 0;
  font-weight: 300;
}
@media (max-width: 850px) {
  .testimonial-user {
    flex-direction: column;
  }
  .testimonial-user-right {
    width: 90%;
  }
}

@media (max-width: 850px) {
  .testimonial-user {
    width: 80%;
  }
}
</style>